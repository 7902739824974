// React
import { React, useState, useEffect } from "react";

// Prop-types
import PropTypes from "prop-types";

// @mui
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FirstPageOutlinedIcon from "@mui/icons-material/FirstPageOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import LastPageOutlinedIcon from "@mui/icons-material/LastPageOutlined";

// dayjs
import dayjs from "dayjs";

// dataService.js
import { fetchStatsForMonth } from "../dataService";

// A ---------------------------------------------------------------------- M

const ApiKeysUsageTableToolbar = ({ selectedDate, setSelectedDate }) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" fontWeight="bold">
          Tier-1 Certifications
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={6} justifyContent="flex-end">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={["year", "month"]}
            label="Select Billing Period"
            value={selectedDate}
            onChange={(newValue) => setSelectedDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === "rtl" ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {theme.direction === "rtl" ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const ApiKeysUsageTable = ({ apiKeys, rootCollectionName }) => {
  const [filteredStats, setFilteredStats] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("count");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await fetchStatsForMonth(apiKeys, rootCollectionName, selectedDate);
      setFilteredStats(data);
    } catch (error) {
      console.error("Error fetching monthly stats:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedDate]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedStats = filteredStats.sort((a, b) =>
    order === "desc"
      ? b[orderBy].localeCompare
        ? b[orderBy].localeCompare(a[orderBy])
        : b[orderBy] - a[orderBy]
      : a[orderBy].localeCompare
      ? a[orderBy].localeCompare(b[orderBy])
      : a[orderBy] - b[orderBy]
  );

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sortedStats.length) : 0;

  return (
    <Box>
      <ApiKeysUsageTableToolbar selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
      <Divider sx={{ my: 2 }} />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {["ID", "Name", "Company", "Type", "Count"].map((header) => (
                <TableCell key={header}>
                  <TableSortLabel active={orderBy === header.toLowerCase()} direction={orderBy === header.toLowerCase() ? order : "asc"} onClick={() => handleRequestSort(header.toLowerCase())}>
                    {header}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              sortedStats.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((stat) => (
                <TableRow key={stat.id}>
                  <TableCell>{stat.id || <span style={{ color: "gray" }}>N/A</span>}</TableCell>
                  <TableCell>{stat.name || <span style={{ color: "gray" }}>N/A</span>}</TableCell>
                  <TableCell>{stat.company || <span style={{ color: "gray" }}>N/A</span>}</TableCell>
                  <TableCell>{stat.type || <span style={{ color: "gray" }}>N/A</span>}</TableCell>
                  <TableCell>{stat.count}</TableCell>
                </TableRow>
              ))
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={5}
                count={apiKeys.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

ApiKeysUsageTable.propTypes = {
  apiKeys: PropTypes.array.isRequired,
  rootCollectionName: PropTypes.string.isRequired,
};

export default ApiKeysUsageTable;
