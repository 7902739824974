// Firebase
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const firebaseConfig = {
  apiKey: "AIzaSyDUVebqHLsvgxVDhtbfoUZYpsBh8diQraI",
  authDomain: "token-and-known-address-db.firebaseapp.com",
  projectId: "token-and-known-address-db",
  storageBucket: "token-and-known-address-db.appspot.com",
  messagingSenderId: "404229838220",
  appId: "1:404229838220:web:f90781e721640c071b32a9",
  measurementId: "G-2D2BNR1KQZ",
};

// Init Firebase
const app = initializeApp(firebaseConfig);

// Init services

// Firebase Authentication
export const auth = getAuth(app);

// Firebase Firestore
export const db = getFirestore(app);

export default app;
