// Firebase
import { db } from "./configuration/firebase";
import { collection, doc, getDoc, query, where, getDocs, Timestamp, getCountFromServer } from "firebase/firestore";

// Fetch all API keys and keys for a specific user
export const fetchUserApiKeysAndKeys = async (userID) => {
  const ownerRelationsRef = collection(db, "owner_relations");

  // Fetch apikeys_list
  const apikeysListRef = collection(ownerRelationsRef, `${userID}/apikeys_list`);
  const apikeysSnapshot = await getDocs(apikeysListRef);

  const apikeys = apikeysSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  // Fetch keys_list
  const keysListRef = collection(ownerRelationsRef, `${userID}/keys_list`);
  const keysSnapshot = await getDocs(keysListRef);

  const keys = keysSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return { apikeys, keys };
};

// Fetch overview stats
export const fetchStatsForRecords = async (list, rootCollectionName, days = 5) => {
  const promises = Array.from({ length: days }).map(async (_, index) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - index);
    currentDate.setHours(0, 0, 0, 0);

    const startOfDayUnixSeconds = currentDate.getTime() / 1000;
    const startOfDayTimestamp = new Timestamp(startOfDayUnixSeconds, 0);

    currentDate.setHours(23, 59, 59, 999);
    const endOfDayUnixSeconds = currentDate.getTime() / 1000;
    const endOfDayTimestamp = new Timestamp(endOfDayUnixSeconds, 0);

    const counts = await Promise.all(
      list.map(async (item) => {
        const billingRef = collection(db, `${rootCollectionName}/${item.id}/billing`);
        const q = query(billingRef, where("timestamp", ">=", startOfDayTimestamp), where("timestamp", "<=", endOfDayTimestamp));
        const snapshot = await getCountFromServer(q);
        return snapshot.data().count;
      })
    );

    const total = counts.reduce((acc, curr) => acc + curr, 0);

    return {
      date: currentDate.toISOString().split("T")[0],
      total,
    };
  });

  return Promise.all(promises);
};

// Fetch stats for month
export const fetchStatsForMonth = async (list, rootCollectionName, selectedDate) => {
  const startOfMonth = new Date(selectedDate.year(), selectedDate.month(), 1);
  const endOfMonth = new Date(selectedDate.year(), selectedDate.month() + 1, 0);
  endOfMonth.setHours(23, 59, 59, 999);

  const startTimestamp = new Timestamp(startOfMonth.getTime() / 1000, 0);
  const endTimestamp = new Timestamp(endOfMonth.getTime() / 1000, 0);

  const promises = list.map(async (item) => {
    const billingRef = collection(db, `${rootCollectionName}/${item.id}/billing`);
    const q = query(billingRef, where("timestamp", ">=", startTimestamp), where("timestamp", "<=", endTimestamp));
    const snapshot = await getCountFromServer(q);

    const docRef = doc(db, rootCollectionName, item.id);
    const docSnapshot = await getDoc(docRef);
    const docData = docSnapshot.data();

    return {
      id: item.id,
      name: docData.name || docData.Name || "",
      company: docData.company || docData.Company || "",
      type: docData.type || docData.Type || "",
      count: snapshot.data().count,
    };
  });

  return Promise.all(promises);
};
