// React
import React from "react";

// @mui
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// A ---------------------------------------------------------------------- M

const WidgetCard = ({ totalCertifications, title, lineColor }) => {
  return (
    <Card
      sx={{
        margin: "auto",
        border: "none",
        boxShadow: "none",
        maxWidth: 300,
      }}
    >
      <CardContent
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography align="center" variant="h6" component="div" sx={{ marginBottom: 2, color: "text.secondary" }}>
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 110,
            width: 120,
            backgroundColor: lineColor || "#b2dfdb",
            color: "white",
            borderRadius: "50%",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "box-shadow 0.3s, transform 0.3s",
            "&:hover": {
              boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.3)",
              transform: "scale(1.1)",
            },
          }}
        >
          <Typography variant="h4" component="span" sx={{ fontWeight: "bold" }}>
            {totalCertifications}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default WidgetCard;
