// React
import React from "react";

// react-chartjs-2
import { Line } from "react-chartjs-2";

// chart.js
import { Chart as ChartJS, PointElement, LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from "chart.js";

// A ---------------------------------------------------------------------- M

ChartJS.register(PointElement, LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const OverviewChart = ({ records }) => {
  const data = {
    labels: records.map((record) => record.x),
    datasets: [
      {
        label: "Total Certifications",
        data: records.map((record) => record.total),
        borderColor: "#2196f3",
        backgroundColor: "rgba(33, 150, 243, 0.1)",
        borderWidth: 2,
        tension: 0.1,
      },
      {
        label: "Tier-1 Certifications",
        data: records.map((record) => record.totalByApiKeys),
        borderColor: "#ef5350",
        backgroundColor: "rgba(239, 83, 80, 0.1)",
        borderWidth: 2,
        tension: 0.1,
      },
      {
        label: "Tier-2 Certifications",
        data: records.map((record) => record.totalByKeys),
        borderColor: "#66bb6a",
        backgroundColor: "rgba(102, 187, 106, 0.1)",
        borderWidth: 2,
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Certifications",
        },
        suggestedMin: 0,
        suggestedMax: Math.max(...records.map((record) => record.total), ...records.map((record) => record.totalByApiKeys), ...records.map((record) => record.totalByKeys)) + 2,
        ticks: {
          stepSize: 1,
          precision: 0,
        },
      },
    },
  };

  return <Line options={options} data={data} height="130vh" />;
};

export default OverviewChart;
