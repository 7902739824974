// React
import { React, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

// Contexts
import { UserAuth } from "../contexts/AuthContext";

// @mui
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

// Components
import Copyright from "../components/Copyright";

// A ---------------------------------------------------------------------- M

const Signin = () => {
  const { user, signIn } = UserAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [invalidAccount, setInvalidAccount] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, []);

  const handleSignin = async () => {
    try {
      const userCredential = await signIn(email, password);
      if (userCredential.user.uid) {
        navigate("/");
      }
    } catch (error) {
      console.error("Signin error:", error.message);
      setInvalidAccount(true);
      setPassword("");
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: "url('/images/signin_bg.webp')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 4,
            boxShadow: 4,
            borderRadius: 4,
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" fontWeight="bold" mb="5%">
            Sign In
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField variant="outlined" margin="normal" required fullWidth id="email" label="Email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} aria-label="Email" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                aria-label="Password"
              />
              {invalidAccount && <Typography color="red">Email address or password wrong. Try again.</Typography>}
              <Link to="/reset-password" style={{ textDecoration: "none" }}>
                <Typography variant="body2" color="primary" align="right">
                  Reset Password
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" color="primary" onClick={handleSignin} aria-label="Sign In" sx={{ py: 2, borderRadius: 3 }}>
                Sign In
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box sx={{ mt: 2, pb: 2 }}>
        <Copyright />
      </Box>
    </Box>
  );
};

export default Signin;
