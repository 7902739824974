// React
import { Route, Routes } from "react-router-dom";
import { AuthContextProvider } from "./contexts/AuthContext";

// Routes
import ProtectedRoute from "./pages/ProtectedRoute";
import ResetPassword from "./pages/ResetPassword";
import Signin from "./pages/Signin";
import BlockchainDashboard from "./pages/BlockchainDashboard";

// A ---------------------------------------------------------------------- M

const App = () => {
  return (
    <AuthContextProvider>
      <Routes>
        <Route path="/signin" element={<Signin />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <BlockchainDashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
    </AuthContextProvider>
  );
};

export default App;
