// React
import React from "react";

// @mui
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

// A ---------------------------------------------------------------------- M

const Copyright = () => {
  return (
    <>
      <Typography variant="body2" color="black" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://armilis.com/">
          Armilis
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
      <Typography variant="body2" color="black" align="center">
        All Rights Reserved
      </Typography>
      <Typography variant="body2" color="black" align="center">
        v1.0.1
      </Typography>
    </>
  );
};

export default Copyright;
