// React
import React, { useState, useEffect, useCallback } from "react";

// Contexts
import { UserAuth } from "../contexts/AuthContext";

// Components
import OverviewChart from "../components/OverviewChart";
import ApiKeysUsageTable from "../components/ApiKeysUsageTable";
import KeysUsageTable from "../components/KeysUsageTable";
import WidgetCard from "../components/WidgetCard";

// @mui
import AppBar from "@mui/material/AppBar";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import LogoutIcon from "@mui/icons-material/Logout";

// dataService.js
import { fetchUserApiKeysAndKeys, fetchStatsForRecords } from "../dataService";

// A ---------------------------------------------------------------------- M

const Logo = () => {
  const logoSrc = "/logo/armilis-logo.png";
  return (
    <Link href="/">
      <img src={logoSrc} alt="Company logo" height="35" />
    </Link>
  );
};

const BlockchainDashboard = () => {
  const { user, handleLogout } = UserAuth();

  const [records, setRecords] = useState([]);
  const [apiKeys, setApiKeys] = useState([]);
  const [keys, setKeys] = useState([]);
  const [totalByApiKeys, setTotalByApiKeys] = useState(0);
  const [totalByKeys, setTotalByKeys] = useState(0);
  const [totalCertifications, setTotalCertifications] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      // Fetch API keys and keys for the user
      const { apikeys, keys } = await fetchUserApiKeysAndKeys(user.uid);

      console.log(apikeys);
      console.log(keys);

      setApiKeys(apikeys);
      setKeys(keys);

      // Fetch daily stats from the root collections
      const [apiKeysRecords, keysRecords] = await Promise.all([fetchStatsForRecords(apikeys, "apikeys_and_onchain_count", 5), fetchStatsForRecords(keys, "keys_and_onchain_count", 5)]);

      console.log(apiKeysRecords);
      console.log(keysRecords);

      // Combine and calculate totals
      const combinedRecords = apiKeysRecords.map((record, index) => {
        const keysRecord = keysRecords[index] || { total: 0 };
        return {
          x: record.date, // Use the date from apiKeysRecords as the x-axis label
          total: record.total + keysRecord.total, // Sum up the total certifications from API keys and public keys
          totalByApiKeys: record.total, // Store the total certifications for API keys
          totalByKeys: keysRecord.total, // Store the total certifications for public keys
        };
      });

      setRecords(combinedRecords.reverse());
      setTotalByApiKeys(apiKeysRecords.reduce((acc, rec) => acc + rec.total, 0));
      setTotalByKeys(keysRecords.reduce((acc, rec) => acc + rec.total, 0));
      setTotalCertifications(combinedRecords.reduce((acc, rec) => acc + rec.total, 0));
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setIsLoading(false);
    }
  }, [user.uid]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <AppBar position="fixed">
          <Toolbar>
            <Logo />
            <Box sx={{ flexGrow: 1 }} />
            <Tooltip title="Logout" placement="top">
              <IconButton color="inherit" onClick={handleLogout}>
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Toolbar />
      </Grid>
      {isLoading ? (
        <Grid item container justifyContent="center" mt="30%">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Grid item container xs={12} spacing={4} p={3}>
          <Grid item container xs={12} alignItems="center">
            <Grid item container xs={2} justifyContent="center" spacing={1}>
              <Grid item xs={10}>
                <WidgetCard totalCertifications={totalCertifications} title="Total Certifications (Latest 5 Days)" lineColor="#2196f3" />
              </Grid>
              <Grid item xs={10}>
                <WidgetCard totalCertifications={totalByApiKeys} title="Tier-1 Certifications (Latest 5 Days)" lineColor="#ef5350" />
              </Grid>
              <Grid item xs={10}>
                <WidgetCard totalCertifications={totalByKeys} title="Tier-2 Certifications (Latest 5 Days)" lineColor="#66bb6a" />
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <OverviewChart records={records} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ border: "1px solid #e0e0e0", boxShadow: 3, borderRadius: 2, mb: 4 }}>
              <CardContent>
                <ApiKeysUsageTable apiKeys={apiKeys} rootCollectionName="apikeys_and_onchain_count" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ border: "1px solid #e0e0e0", boxShadow: 3, borderRadius: 2, mb: 4 }}>
              <CardContent>
                <KeysUsageTable keys={keys} rootCollectionName="keys_and_onchain_count" />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default BlockchainDashboard;
