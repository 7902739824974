// React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Contexts
import { UserAuth } from "../contexts/AuthContext";

// @mui
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

// Components
import Copyright from "../components/Copyright";

// A ---------------------------------------------------------------------- M

const ResetPasswordDialog = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>Check your email inbox to open the password reset link and then proceed to signin.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Sign In
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ResetPassword = () => {
  const { resetPassword } = UserAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [openReset, setOpenReset] = useState(false);

  const handleReset = async () => {
    await resetPassword(email);
    setOpenReset(true);
  };

  const handleCloseReset = () => {
    setOpenReset(false);
    navigate("/signin");
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage: "url('/images/signin_bg.webp')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Container
          component="main"
          maxWidth="sm"
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              p: 4,
              boxShadow: 4,
              borderRadius: 4,
              bgcolor: "background.paper",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" fontWeight="bold" mb="5%">
              Reset Password
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField variant="outlined" margin="normal" required fullWidth id="email" label="Email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} aria-label="Email" />
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth variant="contained" color="primary" onClick={handleReset} aria-label="Reset Password" disabled={!email} sx={{ py: 2, borderRadius: 3 }}>
                  Reset Password
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Box sx={{ mt: 2, pb: 2 }}>
          <Copyright />
        </Box>
      </Box>

      {openReset && <ResetPasswordDialog open={openReset} handleClose={handleCloseReset} />}
    </>
  );
};

export default ResetPassword;
